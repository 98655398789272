import PropTypes from "prop-types";
import React from 'react';
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";
import FooterData from '../../data/Footer/footerItem.json';

const FooterLinkItem = () => {
    const { user, userToken, loading, checkAuthLoading, isAuthenticated } =
    useSelector((state) => state.user);
      // Get the first object from the array
      const firstObject = FooterData[0];
      console.log('firstObject: ', firstObject);
    return (
      <>
       {user &&  
          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
            <div className="footer-widget">
              <h4 className="footer-widget-title">{firstObject.title}</h4>
              <div className="footer-widget-content">
                <ul>
                  {firstObject.list.map((single, key) => (
                    <li key={key}>
                      <Link to={single.url}>
                        {single.icon && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: single.icon,
                            }}
                          ></span>
                        )}
                        <span style={{ paddingRight: "5px" }}></span>
                        {single.text}{" "}
                        {single?.badge && (
                          <span className="ft-badge">{single.badge}</span>
                        )}{" "}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        }
        {FooterData &&
          FooterData.slice(1).map((single, key) => {
            return (
              <div
                key={key}
                className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6"
              >
                <div className="footer-widget">
                  <h4 className="footer-widget-title">{single?.title}</h4>
                  <div className="footer-widget-content">
                    <ul>
                      {single?.list.map((single, key) => (
                        <li key={key}>
                          <Link to={single.url}>
                            {single.icon && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: single.icon,
                                }}
                              ></span>
                            )}
                            <span style={{ paddingRight: "5px" }}></span>
                            {single.text}{" "}
                            {single?.badge && (
                              <span className="ft-badge">{single.badge}</span>
                            )}{" "}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
}

FooterLinkItem.propTypes = {
    single: PropTypes.object
};

export default FooterLinkItem;
