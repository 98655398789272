import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {NavLink} from 'react-router-dom';
// import { logout } from '../../store/mutation/userSlice';
import { logout } from '../../../store/mutation/userSlice';

const PharmaEdgeNavBar = () => {
    const { user, userToken, loading, checkAuthLoading ,isAuthenticated} = useSelector(
        (state) => state.user
      );
      
      const dispatch=useDispatch()
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/PharmaEdge"}><span className="menu-text">Home</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/PharmaEdge-about"}><span className="menu-text">About Us</span></NavLink>
                </li>
                {user && <li className="has-children">
                    <NavLink ><span className="menu-text">Services</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/pmt-aspirants"}><span className="menu-text">PMT Aspirants</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/pharma-sales"}><span className="menu-text">Pharma Sales</span></NavLink></li>
                    </ul>
                </li>}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink>
                </li>
                {user?

                <li>
                    <NavLink onClick={()=>dispatch(logout())}><span className="menu-text">Logout </span></NavLink>
                </li>:null
                }
            </ul>
        </nav>
    )
}

export default PharmaEdgeNavBar
